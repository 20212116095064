import {osName, osVersion, browserName, browserVersion} from 'react-device-detect';

const _getUserAgentParserLibResult = () => {
    return {
        browser : {
            name : browserName,
            version : browserVersion
        },
        os : {
            name : osName,
            version : osVersion
        }
    };
};

const _convertUserAgentDataResult = (PlatformInfo, result) => {
    const brandsMapping = {
        chrome : ['google chrome'],
        edge : ['microsoft edge'],
        samsung : ['samsung internet']
    };

    const osMapping = {
        windows : ['windows'],
        android : ['android']
    };

    //Map browser name and version
    let browserName = '';
    let browserVersion = [];

    if(result.hasOwnProperty('brands') === true) {
        for(let i = 0, len = result.brands.length; i < len; ++ i) {
            let brandsItem = result.brands[i];

            if(brandsItem.hasOwnProperty('brand') === false) {
                continue;
            }

            let compareBrandName = brandsItem.brand.toLowerCase();

            for(let browserIdentifier in brandsMapping) {
                let browserBrandStrings = brandsMapping[browserIdentifier];

                if(browserBrandStrings.indexOf(compareBrandName) !== -1) {
                    browserName = browserIdentifier;

                    if(brandsItem.hasOwnProperty('version') === true) {
                        browserVersion = _parseVersionNumber(brandsItem.version);
                    }

                    break;
                }
            }
        }
    }

    //Map OS name and version.
    let osName = '';
    let osVersion = [];

    if(result.hasOwnProperty('platform') === true) {
        let compareOsName = result.platform.toLowerCase();

        for(let osIdentifier in osMapping) {
            let osStrings = osMapping[osIdentifier];

            if(osStrings.indexOf(compareOsName) !== -1) {
                osName = osIdentifier;

                if(result.hasOwnProperty('platformVersion') === true) {
                    osVersion = _parseVersionNumber(result.platformVersion);
                }

                break;
            }
        }
    }

    //Finally, return the converted result.
    return {
        os : {
            name : osName,
            versionSegments : osVersion,
            versionMajor : osVersion.length > 0
                ? osVersion[0]
                : -1
        },
        browser : {
            name : browserName,
            versionSegments : browserVersion,
            versionMajor : browserVersion.length > 0
                ? browserVersion[0]
                : -1
        }
    };
};

const _convertUserAgentParserLibResult = (PlatformInfo, result) => {
    const brandsMapping = {
        chrome : ['chrome'],
        safari : ['mobile safari'],
        edge : ['edge'],
        samsung : ['samsung internet'],
        firefox : ['firefox']
    };

    const osMapping = {
        windows : ['windows'],
        android : ['android'],
        ios : ['ios']
    };

    //Map browser name and version.
    let browserName = '';
    let browserVersion = [];
    let browserVersionMajor = -1;

    if(result.hasOwnProperty('browser') === true) {
        if(result.browser.hasOwnProperty('name') === true) {
            let compareBrowserName = result.browser.name.toLowerCase();

            for(let browserIdentifier in brandsMapping) {
                let browserBrandStrings = brandsMapping[browserIdentifier];

                if(browserBrandStrings.indexOf(compareBrowserName) !== -1) {
                    browserName = browserIdentifier;

                    if(result.browser.hasOwnProperty('version') === true) {
                        browserVersion = _parseVersionNumber(result.browser.version);
                    }

                    if(result.browser.hasOwnProperty('major') === true) {
                        browserVersionMajor = window.parseInt(result.browser.major);
                    }
                    else if(browserVersion.length > 0) {
                        browserVersionMajor = browserVersion[0];
                    }

                    break;
                }
            }
        }
    }

    //Map OS name and version.
    let osName = '';
    let osVersion = [];
    let osVersionMajor = -1;

    if(result.hasOwnProperty('os') === true) {

        if(result.os.hasOwnProperty('name') === true) {
            let compareOsName = result.os.name.toLowerCase();

            for(let osIdentifier in osMapping) {
                let osStrings = osMapping[osIdentifier];

                if(osStrings.indexOf(compareOsName) !== -1) {
                    osName = osIdentifier;

                    if(result.os.hasOwnProperty('version') === true) {
                        osVersion = _parseVersionNumber(result.os.version);
                    }

                    if(result.os.hasOwnProperty('major') === true) {
                        osVersionMajor = window.parseInt(result.os.major);
                    }
                    else if(osVersion.length > 0) {
                        osVersionMajor = osVersion[0];
                    }

                    break;
                }
            }
        }
    }

    //Finally, return the converted result.
    return {
        os : {
            name : osName,
            versionSegments : osVersion,
            versionMajor : osVersionMajor
        },
        browser : {
            name : browserName,
            versionSegments : browserVersion,
            versionMajor : browserVersionMajor
        }
    };
};

const _parseVersionNumber = (versionNumber) => {
    if(versionNumber === null 
        || typeof versionNumber === 'undefined') {

        return [];
    }

    if(typeof versionNumber === 'bigint'
        || typeof versionNumber === 'number') {

        return [
            versionNumber
        ];
    }

    if(typeof versionNumber !== 'string') {
        throw new Error('Unable to parse version number; input type must be either a bigint, number or string.');
    }

    var versionNumberSegments = versionNumber.split('.');
    
    versionNumber = [];

    for(var i = 0, len = versionNumberSegments.length; i < len; ++ i) {
        var versionNumberSegment = versionNumberSegments[i];

        if(versionNumberSegment.length === 0) {
            continue;
        }

        versionNumberSegment = window.parseInt(versionNumberSegment);

        if(window.isNaN(versionNumberSegment) === true) {
            continue;
        }

        versionNumber.push(versionNumberSegment);
    }

    return versionNumber;
};

const PlatformInfo = {

    RESULT_OK : 'OK',
    RESULT_FAILED : 'FAILED',

    SOURCE_UNKNOWN : 'UNKN',
    SOURCE_UA_DATA : 'UA-DATA',
    SOURCE_UA_PARSER_LIB : 'UA-PARSER',

    request : () => {
        return new Promise(resolve => {
            let isUserAgentDataSupported = false;

            for(let property in window.navigator) {
                if(property === 'userAgentData') {
                    isUserAgentDataSupported = true;
                    break;
                }
            }

            if(isUserAgentDataSupported === true) {
                window.navigator.userAgentData
                    .getHighEntropyValues([
                        'platform', 
                        'platformVersion', 
                        'mobile'
                    ])
                    .then(
                        (values) => {
                            resolve(
                                {
                                    resultCode : PlatformInfo.RESULT_OK,
                                    sourceCode : PlatformInfo.SOURCE_UA_DATA,
                                    resultData : _convertUserAgentDataResult(PlatformInfo, values),
                                    resultDataRaw : values
                                }
                            );
                        },
                        (reason) => {
                            let parserResult = _getUserAgentParserLibResult();

                            resolve(
                                {
                                    resultCode : PlatformInfo.RESULT_OK,
                                    sourceCode : PlatformInfo.SOURCE_UA_PARSER_LIB,
                                    resultData : _convertUserAgentParserLibResult(PlatformInfo, parserResult),
                                    resultDataRaw : parserResult
                                }
                            );
                        }
                    );

                return;
            }

            let parserResult = _getUserAgentParserLibResult();

            resolve(
                {
                    resultCode : PlatformInfo.RESULT_OK,
                    sourceCode : PlatformInfo.SOURCE_UA_PARSER_LIB,
                    resultData : _convertUserAgentParserLibResult(PlatformInfo, parserResult),
                    resultDataRaw : parserResult
                }
            );
        });
    }

};

export const {
    request : requestPlatformInformation
} = PlatformInfo;

export default PlatformInfo;