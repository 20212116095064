import React from 'react';
import {Routes} from "react-router-dom";

// Routing pages
import {routes as routeList} from "./app/routes";
import {fetchWords} from "./API";
import {requestPlatformInformation} from "./PlatformInfo";
import {selectFetched, selectWords, setFetched, setWords} from "./features/translate/translateSlice";
import {connect} from "react-redux";
import Loader from "./components/Loader";

import logo from "./img/assets/varb-logo.svg";

import ReactGA from "react-ga4";
import {Content} from "./components/Content";
import {Word} from "./components/Word";
import {mapRoutes, setIsBlocked, setGlobalPlatformInformation, withNavigate} from "./app/config";

console.log("Serving from env:", process.env.REACT_APP_ENV);

if (process.env.NODE_ENV === "production" && process.env.REACT_APP_ENV === "production") {
    console.log("Google Analytics is initialized");
    ReactGA.initialize('G-5031PBKEG3');
}

class Router extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
        }

    }

    /**
     *
     * @returns {Promise<*>}
     */
    componentDidMount = async () => {
        requestPlatformInformation().then((resultData) => {
            setGlobalPlatformInformation(resultData);
        });

        const words = selectWords(this.props),
            fetched = selectFetched(this.props);

        const prevLink = localStorage.getItem("PREV-LINK");
        const prevLinkBefore = localStorage.getItem("PREV-LINK-BEFORE");

        if (prevLink && prevLinkBefore) {
            localStorage.setItem("PREV-LINK", prevLinkBefore);
        }

        if (fetched === true) {
            this.setState({
                isLoaded: true,
            })
            return words;
        }

        fetchWords().then((res) => {

            this.props.dispatch(setWords(res.data.data))
            this.props.dispatch(setFetched(true))
        });

        setTimeout(() => {
            this.setState({
                isLoaded: true,
            })
        }, (1000));
    }

    /**
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.location !== prevProps.location) {

            const linkBefore = localStorage.getItem("PREV-LINK");
            localStorage.setItem("PREV-LINK-BEFORE", linkBefore);
            localStorage.setItem("PREV-LINK", this.props.location.pathname);

            if (window.swUpdateReady) {
                window.swUpdateReady = false;
                window.stop();
                window.location.reload();
            }

            setIsBlocked(false);

        }
    }

    render() {

        const prevLink = localStorage.getItem("PREV-LINK");

        try {
            if (this.state.isLoaded === true) {

                const browserRoutes = mapRoutes(routeList, "", {prevLink});

                return (
                    <React.Fragment>
                        <Routes>
                            {browserRoutes}
                        </Routes>
                    </React.Fragment>
                );
            } else {

                return (
                    <div className="d-flex flex-column align-items-center justify-content-center h-100">

                        <div className={"mb-auto"}>
                            <Content bb={true}>
                                <img src={logo} alt="" height={75}/>
                            </Content>
                        </div>

                        <Content>
                            <div className={"text-center"}>
                                <div className={"d-flex justify-content-center mb-4"}>
                                    <Loader/>
                                </div>
                                <h3 className={"mb-0"}>
                                    <Word tag={"LOADING-TEXT"}/>
                                </h3>
                            </div>
                        </Content>
                    </div>
                )
            }
        } catch (e) {
            console.error("Error!!!", e);
        }



    }

}

const MapStateToProps = (state) => ({
    translate: state.translate,
});

export default connect(MapStateToProps)(withNavigate(Router));
