import React, {useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-regular-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import { hasGlobalPlatformInformation, getGlobalPlatformInformation } from "../app/config";

import {requestPlatformInformation} from '../PlatformInfo';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function Camera(props) 
{
    let acceptAttributeValue  ='image/*';

    if(hasGlobalPlatformInformation() === true) {
        let platformInformation = getGlobalPlatformInformation();

        if(platformInformation.resultCode === 'OK'
            && platformInformation.resultData.os.name === 'android'
            && platformInformation.resultData.os.versionMajor >= 14
            && platformInformation.resultData.browser.name === 'chrome') {

            acceptAttributeValue = null;
        }
    }

    const defaults = {
        supplyId: null,
        wrap: useRef(null),
        imageWrapperSelector: "",
        inputElm: useRef(null),
        labelElm: useRef(null),
        id: "upload",
        handlers: {},
        showImageAfterUpload: true,
        acceptAttribute: acceptAttributeValue
    }

    const properties = {...defaults, ...props}

    const handlers = {
        ...{
            upload: file => {
                //...
            },
            postUpload(file) {

                if (properties.showImageAfterUpload !== true) {
                    return false;
                }

                const imgURL = URL.createObjectURL(file),
                    img = document.createElement('img');

                img.onload = function () {
                    URL.revokeObjectURL(imgURL);
                };

                img.src = imgURL;
                if (properties.imageWrapperSelector === "") {
                    properties.wrap.current.innerHTML = "";
                    properties.wrap.current.appendChild(img);
                } else {
                    properties.wrap.current.remove();
                    const imgWrapper = document.querySelector(properties.imageWrapperSelector);
                    imgWrapper.appendChild(img);
                }
            }
        }, ...props.handlers
    }

    return (
        <div ref={properties.wrap} className="gallery__add">
            <input type="file" id={properties.id} ref={properties.inputElm} name="photo"
                   accept={properties.acceptAttribute}
                   onChange={() => {
                       const input = properties.inputElm.current;
                       const file = input.files[0];

                       handlers.upload(file);
                       handlers.postUpload(file);
                   }}/>

            <div className={"input-overlay bg-light border d-flex align-items-center justify-content-center"}
                 style={{
                     // width: "150px",
                     height: "100%"
                 }}
                 onClick={() => properties.inputElm.current.click()}>
                    <span className="fa-stack text-grey">
                        <FontAwesomeIcon icon={faCircle} className={"fa-stack-2x"}/>
                        <FontAwesomeIcon icon={faPlus} className={"fa-stack-1x"}/>
                    </span>
            </div>




        </div>
    )

}
